<template>
    <div class="mr-10 ml-10">
        <TabNav v-if="this.$route.params.uuid !== 'new'" :elements="tab"></TabNav>

        <TitleBar :loading="loading">{{ $t("invoice.payment.title", {number: invoice_number}) }}</TitleBar>

        <v-card
            v-if="payments"
            class="mx-auto"
            elevation="0"
            outlined
        >
            <v-list three-line>
                <v-list-item-group>
                    <template v-for="(item, index) in payments">
                        <v-list-item :key="item.uuid"
                                     :to="'/incomes/invoices/' + $route.params.uuid + '/payments/' + item.uuid">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                            $t("invoice.payment.headline", {
                                                amount: item.amount,
                                                currency: item.currency_code
                                            })
                                        }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                        class="text--primary"
                                    >
                                        {{ $t("invoice.payment.using", {method: item.payment_method}) }}<br>
                                        {{ item.description }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text v-text="item.paid_at"></v-list-item-action-text>
                                    <v-list-item-action-text v-text="item.reference"></v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < payments.length - 1"
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>

        <v-divider v-if="payments" class="mt-5 mb-5"></v-divider>

        <h3 class="mb-5">{{ $t("invoice.payment.add") }}</h3>

        <PaymentDetails @payment-updated="fetchPayments()"></PaymentDetails>

    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import TabNav from "@/components/TabNav.vue";
import PaymentDetails from "@/components/Invoices/PaymentDetails.vue";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Payments",
    components: {TitleBar, PaymentDetails, TabNav},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.invoices"),
                to: "/incomes/invoices",
                exact: true
            },
            {
                text: this.$t("invoice.payments"),
                to: "/incomes/invoices/" + this.$route.params.uuid + "/payments",
                exact: false
            }
        ]);

        this.fetchPayments();

    },
    data() {
        return {
            tab: [
                {
                    text: this.$t("invoice.edit"),
                    to: "/incomes/invoices/" + this.$route.params.uuid
                },
                {
                    text: this.$t("invoice.payments"),
                    to: "/incomes/invoices/" + this.$route.params.uuid + "/payments",
                    active: true
                }
            ],
            payments: [],
            invoice_number: "IXXXXXXXX-0",
            loading: false
        };
    },
    methods: {
        fetchPayments() {
            this.loading = true;
            Accounting.get("/incomes/invoices/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.payments = data.payments;
                    this.invoice_number = data.invoice_number;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        editPayment(payment) {
            // eslint-disable-next-line no-unused-vars
            const invoice = this.$route.params.uuid;
            console.log(payment);
        }
    }
};
</script>

<style scoped>
.theme--dark.v-tabs > .v-tabs-bar, .theme--dark.v-tabs-items {
    background: #121212 !important;
}

.delete-icon:hover {
    color: #B71C1C !important;
}
</style>